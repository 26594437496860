import httpClient from "../config/httpClient";
import { CallType } from "@/api/constants/enums";
import { TrackingFilter } from "@/api/models/tracking/trackingFilter.model";
import { ApprovalValidation } from "../models/approval/approvalValidation.enum";
import { Approval } from "../models/approval/approval.model";
import { Identification } from "../models/sale/identification.model";

export abstract class SaleProcessService {
	private static readonly SALE_PROCESS_URL = "/sys03/sale-process";

	private static readonly PROCESS_BY = SaleProcessService.SALE_PROCESS_URL.concat("/by");

	private static readonly SEND_URL = "send";
	private static readonly SMS_URL = "sms";
	private static readonly DIGITEL_URL = "digitel";

	private static readonly APPROVALS = "approvals";

	private static readonly ADDITIONAL_CONTACT = "additional-contact";
	private static readonly ADDITIONAL_CONTACTS = "additional-contacts";

	private static readonly DOCUMENT = "document";
	private static readonly DOCUMENTS = "documents";

	private static readonly DASHBOARD_URL = SaleProcessService.SALE_PROCESS_URL.concat("/dashboard");
	private static readonly DASHBOARD_COUNT_URL = SaleProcessService.DASHBOARD_URL.concat("/count");
	private static readonly DASHBOARD_CSV_URL = SaleProcessService.DASHBOARD_URL.concat("/csv");

	public static create(name: string, phone: string, campaign?: string, callType?: CallType) {
		if (campaign && callType) {
			return httpClient.post(this.SALE_PROCESS_URL, {
				name,
				phone,
				campaign,
				callType
			});
		} else {
			return httpClient.post(this.SALE_PROCESS_URL, {
				name,
				phone
			});
		}
	}

	public static delete(id: string) {
		return httpClient.delete(this.SALE_PROCESS_URL + "/" + id + "/soft");
	}

	public static getProcessById(id: number) {
		return this.getProcessBy("id", id);
	}

	public static getProcessByPhone(phone: number) {
		return this.getProcessBy("phone", phone);
	}

	private static getProcessBy(field: string, value: number) {
		const url = `${SaleProcessService.PROCESS_BY}/${field}/${value}`;
		return httpClient.get(url);
	}

	public static patchSaleProcess(saleProcessId: number, saleProcess: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}`;
		delete saleProcess.files;
		delete saleProcess.additionalContacts;
		return httpClient.patch(url, saleProcess);
	}

	public static sendContract(saleProcessId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.SEND_URL}`;
		return httpClient.put(url);
	}

	public static sendSms(saleProcessId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.SEND_URL}/${SaleProcessService.SMS_URL}`;
		return httpClient.put(url);
	}

	public static sendDigitel(saleProcessId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.SEND_URL}/${SaleProcessService.DIGITEL_URL}`;
		return httpClient.put(url);
	}

	// Approvals
	public static postSaleProcessApproval(
		saleProcessId: number,
		approval: { validation: ApprovalValidation; billing?: { firstName: string; lastName: string; identification: Identification } }
	) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.APPROVALS}`;
		return httpClient.post<Approval>(url, { data: [{ ...approval }] });
	}

	// Additional contacts

	public static getAdditionalContacts(saleProcessId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.ADDITIONAL_CONTACTS}`;
		return httpClient.get(url);
	}

	public static postAdditionalContact(saleProcessId: string, additionalContact: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.ADDITIONAL_CONTACT}`;
		return httpClient.post(url, additionalContact);
	}

	public static putAdditionalContact(saleProcessId: string, additionalContact: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.ADDITIONAL_CONTACT}/${additionalContact.id}`;
		return httpClient.put(url, additionalContact);
	}

	public static deleteAdditionalContact(saleProcessId: string, additionalContactId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.ADDITIONAL_CONTACT}/${additionalContactId}`;
		return httpClient.delete(url);
	}

	// Documents

	public static getDocuments(saleProcessId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.DOCUMENTS}`;
		return httpClient.get(url);
	}

	public static getDocument(saleProcessId: string, fileName: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.DOCUMENT}/${fileName}`;
		return httpClient.get(url, { responseType: "blob" });
	}

	public static postDocument(saleProcessId: string, document: any) {
		const formData = new FormData();
		formData.append("fileName", document.fileName);
		formData.append("file", document.file);
		formData.append("type", document.type);
		formData.append("comment", document.comment);
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.DOCUMENT}`;
		return httpClient.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
	}

	public static putDocument(saleProcessId: string, document: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.DOCUMENT}/${document.id}`;
		return httpClient.put(url, {
			fileName: document.fileName,
			type: document.type,
			comment: document.comment
		});
	}

	public static deleteDocument(saleProcessId: string, documentId: string) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${saleProcessId}/${SaleProcessService.DOCUMENT}/${documentId}`;
		return httpClient.delete(url);
	}

	// Dashboard
	private static getDashboardParams({ filters }: { filters: TrackingFilter }): Record<string, string | number | boolean> {
		const { limit, page, id, phone, smsPhone, dateFrom, dateTo, user, status, lastChannel, customerType, customerId } = filters;
		const query: Record<string, string | number | boolean> = {
			limit,
			page,
			id,
			phone,
			smsPhone,
			dateFrom,
			dateTo,
			lastChangeMadeBy: user,
			status,
			lastChannel,
			customerType,
			customerId
		};
		const queryFilterd = Object.entries(query).filter(([, value]) => {
			if (typeof value === "string") return value.length > 0;
			return true;
		});

		return Object.fromEntries(queryFilterd);
	}

	public static getDashboardInformation(filters: TrackingFilter) {
		const url = `${SaleProcessService.DASHBOARD_URL}`;
		const params = SaleProcessService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardCsv(filters: TrackingFilter) {
		const url = `${SaleProcessService.DASHBOARD_CSV_URL}`;
		const params = SaleProcessService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}
	public static getLeadHistoryCsv(id: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${id}/history/csv`;
		return httpClient.get(url);
	}

	public static getDashboardStatsSMSAnswered() {
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=sms-answered`;
		return httpClient.get(url);
	}

	public static getDashboardStatsSMSCertification() {
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=sms-certification`;
		return httpClient.get(url);
	}

	public static getDashboardStatsCompletedToday() {
		const today = new Date().toISOString().substring(0, 10);
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=completed&dateFrom=${today}&dateTo=${today}`;
		return httpClient.get(url);
	}

	public static getDashboardStatsCompletedThisMonth() {
		const date = new Date();
		const currentMonth = date.getMonth();
		const currentYear = date.getFullYear();
		const firstDay = new Date(currentYear, currentMonth, 2).toISOString().substring(0, 10);

		const lastDay = new Date(currentYear, currentMonth + 1, 1).toISOString().substring(0, 10);
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=completed&dateFrom=${firstDay}&dateTo=${lastDay}`;
		return httpClient.get(url);
	}

	public static getDashboardStatsPresalesToday() {
		const today = new Date().toISOString().substring(0, 10);
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=presales&dateFrom=${today}&dateTo=${today}`;
		return httpClient.get(url);
	}

	public static getDashboardStatsPresalesThisMonth() {
		const date = new Date();
		const currentMonth = date.getMonth();
		const currentYear = date.getFullYear();
		const firstDay = new Date(currentYear, currentMonth, 2).toISOString().substring(0, 10);

		const lastDay = new Date(currentYear, currentMonth + 1, 1).toISOString().substring(0, 10);
		const url = `${SaleProcessService.DASHBOARD_COUNT_URL}?status=presales&dateFrom=${firstDay}&dateTo=${lastDay}`;
		return httpClient.get(url);
	}

	public static checkForbiddenStatus(id: any) {
		const url = `${SaleProcessService.SALE_PROCESS_URL}/${id}/checkForbiddenStatus`;
		return httpClient.get(url);
	}
}
