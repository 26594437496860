import { DataService } from "@/api/services/data.service";
import { StorageConfiguration } from "@/constants/constants";

import bulkActions from "./actions/bulkActions";
import productCatalogActions from "./actions/productCatalogActions";
import dropdownsActions from "./actions/dropdownsActions";
import usersActions from "./actions/usersActions";
import { AdminService } from "@/api/services/admin.service";
import { ProductTransitionConfiguration } from "@/api/models/admin/productTransitionConfiguration.model";
import { CharacteristicEquivalence } from "@/api/models/admin/characteristicEquivalence.model";
import { MobileProfileEquivalence } from "@/api/models/admin/mobileProfileEquivalence.model";

const fetchConfiguration = ({ commit }: any, { name }: { name: StorageConfiguration }) => {
	return new Promise((resolve, reject) => {
		DataService.getConfiguration(name)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const deleteConfiguration = ({ commit }: any, { name, data }: { name: StorageConfiguration; data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.deleteConfiguration(name, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const patchConfiguration = ({ commit }: any, { name, data }: { name: StorageConfiguration; data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.patchConfiguration(name, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const putConfiguration = ({ commit }: any, { name, data }: { name: StorageConfiguration; data: any }) => {
	return new Promise((resolve, reject) => {
		DataService.putConfiguration(name, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

// PRODUCTS TRANSITION CONFIGURATION

const getProductsTransitionConfiguration = ({ commit }: any): Promise<ProductTransitionConfiguration[]> => {
	return new Promise((resolve, reject) => {
		AdminService.getProductsTransitionConfiguration()
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const createProductTransitionConfiguration = ({ commit }: any, { product }: { product: string }) => {
	return new Promise((resolve, reject) => {
		AdminService.createProductTransition(product)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const updateProductTransitionConfiguration = ({ commit }: any, product: ProductTransitionConfiguration) => {
	return new Promise((resolve, reject) => {
		AdminService.updateProductTransition(product.id, product)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

// PROMOTIONS EQUIVALENCES CONFIGURATION

const getPromotionsEquivalences = ({ commit }: any): Promise<CharacteristicEquivalence[]> => {
	return new Promise((resolve, reject) => {
		AdminService.getCharacteristicsEquivalences()
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const createPromotionEquivalence = ({ commit }: any, equivalence: CharacteristicEquivalence) => {
	return new Promise((resolve, reject) => {
		AdminService.createCharacteristicEquivalence(equivalence)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const updatePromotionEquivalence = ({ commit }: any, equivalence: CharacteristicEquivalence) => {
	return new Promise((resolve, reject) => {
		AdminService.updateCharacteristicEquivalence(equivalence.id, equivalence)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

// PROMOTIONS MOBILE PROFILE EQUIVALENCES CONFIGURATION

const getMobileProfileEquivalence = ({ commit }: any): Promise<MobileProfileEquivalence[]> => {
	return new Promise((resolve, reject) => {
		AdminService.getMobileProfileEquivalence()
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const createMobileProfileEquivalence = ({ commit }: any, equivalence: MobileProfileEquivalence) => {
	return new Promise((resolve, reject) => {
		AdminService.createMobileProfileEquivalence(equivalence)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

const updateMobileProfileEquivalence = ({ commit }: any, equivalence: MobileProfileEquivalence) => {
	return new Promise((resolve, reject) => {
		AdminService.updateMobileProfileEquivalence(equivalence.id, equivalence)
			.then((response) => {
				resolve(response);
			})
			.catch(reject);
	});
};

export default {
	fetchConfiguration,
	deleteConfiguration,
	patchConfiguration,
	putConfiguration,
	getProductsTransitionConfiguration,
	createProductTransitionConfiguration,
	updateProductTransitionConfiguration,
	getPromotionsEquivalences,
	createPromotionEquivalence,
	updatePromotionEquivalence,
	getMobileProfileEquivalence,
	createMobileProfileEquivalence,
	updateMobileProfileEquivalence,
	...bulkActions,
	...productCatalogActions,
	...dropdownsActions,
	...usersActions
};
