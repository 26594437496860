import { MobileProfileEquivalence, MobileProfileEquivalenceAPI } from "@/api/models/admin/mobileProfileEquivalence.model";

export const mobileProfileEquivalenceAPIToMobileProfileEquivalence = (equivalence: MobileProfileEquivalenceAPI): MobileProfileEquivalence => ({
	id: equivalence.id,
	active: equivalence.active,
	fromProfile: equivalence.fromProfile,
	toProfile: equivalence.toProfile,
	toProduct: equivalence.toProduct,
	createdBy: equivalence.created.by,
	createdAt: equivalence.created.at,
	updatedBy: equivalence.updated.by,
	updatedAt: equivalence.updated.at
});

export const mobileProfileEquivalenceToMobileProfileEquivalenceAPI = (
	equivalence: MobileProfileEquivalence
): Partial<MobileProfileEquivalenceAPI> => ({
	active: equivalence.active,
	fromProfile: equivalence.fromProfile,
	toProfile: equivalence.toProfile,
	toProduct: equivalence.toProduct
});
